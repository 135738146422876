/*Checkboxes styles*/
.selected-project .ginput_container_checkbox ul li input[type="checkbox"] { display: none; }

.selected-project .ginput_container_checkbox ul li input[type="checkbox"] + label {
  position: relative;
  font-family: $open-sans !important;
  font-size: 16px !important;
  display: block;
  padding-left: 35px;
  margin-bottom: 20px;
  color: #000;
  cursor: pointer;
	font-weight: 400 !important;
}

.selected-project .ginput_container_checkbox ul li input[type="checkbox"] + label:last-child { margin-bottom: 0; }

.selected-project .ginput_container_checkbox ul li input[type="checkbox"] + label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .6;
  -webkit-transition: all .12s, border-color .08s;
  transition: all .12s, border-color .08s;
}

.selected-project .ginput_container_checkbox ul li input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/**/
.selected-project .ginput_container_checkbox {
	height: 250px;
	overflow-y: scroll;
	overflow-x: hidden;
	border: 1px solid #BFB5B8;
	padding: 10px 20px;
}

/**/
.select-amount .ginput_container_radio ul li {
  vertical-align: middle;
}
.select-amount .ginput_container_radio ul li span {
	font-size: 16px !important;
    line-height: 24px !important;
    font-family: $open-sans !important;
    color: #000000 !important;
}
.select-amount .ginput_container_radio ul li , .ginput_container_radio ul li , .ginput_container_radio ul li  {
  width: 18%;
}
.select-amount .ginput_container_radio ul li:last-child  {
  width: 46%;
}
.select-amount .ginput_container_radio ul li  input[type="radio"] {
  display: none;
}
.select-amount .ginput_container_radio ul li  input[type="text"] {
  font-family: $open-sans !important;
  font-size: 16px !important;
  width: 100% !important;
	min-width: 94%;
}
/* custom radio button */
#field_2_2 ul.gfield_radio li input[type=radio] ,
.select-amount ul.gfield_radio li input{
  display: none;
}
#field_2_2 ul.gfield_radio li label ,
.select-amount ul.gfield_radio li label {
  padding-left: 35px;
}
#field_2_2 ul.gfield_radio li input[type=radio] label,
.select-amount ul.gfield_radio li input[type=radio] label {
  position: relative;
  cursor: pointer;
}
#field_2_2 ul.gfield_radio li input[type=radio] + label:before,
.select-amount ul.gfield_radio li input[type=radio] + label:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #888;
  position: absolute;
  left: 0;
  top: 6px;
}
#field_2_2 ul.gfield_radio li input[type=radio]:checked + label ,
.select-amount ul.gfield_radio li input[type=radio]:checked + label {
  position: relative;
}
/** radio **/
#field_2_2 ul.gfield_radio li input[type=radio]:checked + label:after,
.select-amount ul.gfield_radio li input[type=radio]:checked + label:after {
  content: "";
  position: absolute;
  top: auto;
  top: 3px;
  left: -4px;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
#field_2_2 ul.gfield_radio li input[type=radio]:checked + label:before,
.select-amount ul.gfield_radio li input[type=radio]:checked + label:before {
  background-color: #000;
  width: 10px;
  height: 10px;
  left: 1px;
  top: 8px;
}

/**/
.selected-project .ginput_container_multiselect select {
	font-size: 16px;
    line-height: 24px;
    font-family: $open-sans;
    color: #000000;
}
.selected-project .gfield_description {
	font-size: 12px !important;
    line-height: 18px;
	font-family: $open-sans !important;
    color: #000000;
}
.fl-photo-caption.fl-photo-caption-hover {
  width: 385px !important;
  top: auto !important;
  right: -100% !important;
  left: 100% !important;
  white-space: normal !important;
  bottom: 0% !important;
  z-index: 10 !important;
}
.donate-form .gform_body #field_2_40 {
	margin-top: 0 !important;
	margin-left: 20px !important;
}

.gform_wrapper #field_2_4 ul.gfield_radio li  input[type=radio]:checked+label {
  box-shadow: 0px 0px 4px -1px #000;
}
.text-heading-section h2 {
	color: #D6C29E !important;
}
.fl-node-5a6188e2e966c > .fl-col-content {
	background: transparent !important;
}

.bootstrap-select.select-country button,
.bootstrap-select.select-project button,
.bootstrap-select.filterStyling button {
  padding: 9px 35px 9px 20px;
  border: 1px solid #5e5f61 !important;
  height: 39px;
  width: 250px;
  font-family: $open-sans;
  font-size: 14px;
  text-align: center !important;
  background-color: transparent !important;
  background-image: url(/wp-content/uploads/2018/01/down-icon.png) !important;
  background-repeat: no-repeat;
  background-position: right 20px top 17px;
  background-size: 11px 6px;
  background-clip: border-box;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  outline: none !important;
  color: #323232 !important;
  outline: none !important;
}
.bootstrap-select.select-country.open button,
.bootstrap-select.select-project.open button,
.bootstrap-select.filterStyling.open button {
  border-radius: 20px 20px 0px 0px;
  box-shadow: none !important;
}
.bootstrap-select.select-country button:focus,
.bootstrap-select.select-project button:focus,
.bootstrap-select.filterStyling button:focus {
  outline: none !important;
}
.bootstrap-select.btn-group .dropdown-menu li {
  text-align: center;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  text-align: center !important;
}
.select-country .dropdown-menu.open {
	top: 36px !important;
}
.pub__distributing article .fl-rich-text p strong {
	display: inline !important;
}
.pub__distributing article .fl-rich-text  strong {
	display: inline !important;
}

// Refactored in source/components/grids/filter-country-info.scss
// ***
// .filter-country-info .fl-post-grid {
// 	display: flex;
// 	flex-flow: row wrap;
// 	justify-content: center;
// 	align-items: center;
// }
.single .fl-sidebar-right aside select, ul.dropdown-menu>li.selected {
	display: none !important;
}
@media screen and (max-width: 768px) {
    // Refactored in source/components/grids/filter-country-info.scss
    // ***
	// .filter-country-info .fl-post-grid {
	// 	display: block;
	// }
			.fl-photo-caption.fl-photo-caption-hover {
    width: 290px !important;
    top: auto !important;
    right: 0 !important;
    left: 5% !important;
    white-space: normal !important;
    bottom: 100% !important;
    z-index: 10 !important;
}

}
@media screen and (max-width: 640px) {
	.select-amount .ginput_container_radio ul li:last-child  {
  width: 100%;
}
	.select-amount .ginput_container_radio ul li , .ginput_container_radio ul li , .ginput_container_radio ul li  {
  width: 33%;
}
	.select-amount .ginput_container_radio ul li span{
		display: inline-block !important;
	}
}
.partial_entry_warning {
display: none !important;
}
.ecfa-image .tooltip {
	width: 320px !important;
}
.ecfa-image .tooltip-inner {
	padding: 10px;
}
