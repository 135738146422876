.filter-country-info {
	position: relative;

	.fl-post-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &::before,
        &::after {
            content: normal;
        }
		.fl-post-column {
            display: flex;
            flex-direction: column;
            min-height: 500px;

			.fl-post-grid-post {
				position: relative;
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;

				.img-responsive,
				.fl-post-image,
				.fl-post-image img {
                    position: absolute;
                    min-height: 100%;
                    min-width: 100%;
                    z-index: 0;
                }

				.fl-post-text {
					display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
					position: relative;
					height: 100%;
                    padding: 0;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%);
                    z-index: 1;

					.fl-post-title {
						width: 100%;
						padding: 0 20px;
                        margin-bottom: 10px;
						font-size: 20px;
						font-weight: 300;
						letter-spacing: 1px;
						line-height: 1.2;
						font-family: $main-font;
						text-transform: uppercase;
						color: #fff;

						a {
							color: #FFFFFF !important;
						}
					}

					p {
						width: 100%;
						margin-bottom: 0;
						padding: 10px 20px;
						background-color: rgba(255, 255, 255, 0.3);
						text-transform: capitalize;
						color: #2B0913;
					}
				}
			}
		}
	}
}
