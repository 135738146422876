/*=============== History block css ============*/
.content-block{
  .fl-module-content{
    .fl-rich-text{
      h2{
        font-size: 60px;
        line-height: 72px;
        color: $light-gold;
        font-weight: 300;
        letter-spacing: 2px;
        font-family: $main-font;
        margin-top: 0;
        margin-bottom: 15px;
      }
      p{
        margin-bottom: 28px;
      }
    }
  }
  &.what-we-believe{
    .fl-module-content{
      .fl-rich-text{
        ul{
          padding-left: 0;
          @media screen and (min-width: $md_breakpoint){
            @include column-count(2);
            margin-left: 16px;
          }
          li{
            font-size: 16px;
            line-height: 22px;
            font-family: $open-sans;
            color: $light-gray;
            margin-bottom: 17px;
            position: relative;
            padding-left: 26px;
            &::before{
              content: "\f111";
              position: absolute;
              left: 0;
              top: 0;
              font-family: FontAwesome;
              color: $light-gray;
              font-size: 5px;

            }
          }
        }
      >  p {
          + p{
            margin-top: 28px;
            margin-bottom: 1px;
          }
        }
      }
    }
  }
}

/*============= leadership-wrapper css here ============*/
.leadership-wrapper{
  .fl-rich-text{
    h2{
      font-size: 60px;
      line-height: 72px;
      color: $light-gold;
      font-weight: 300;
      letter-spacing: 2px;
      font-family: $main-font;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .member-info{
    .fl-rich-text{
      h5{
        font-size: 16px;
        line-height: 24px;
        color: $light-red;
        font-family: $open-sans;
        margin-top: 0;
      }
      p{
        font-size: 14px;
        line-height: 20px;
        color: $lightest-gray;
        font-family: $open-sans;
      }
    }
  }

}

/*========== member-block css here ==============*/
.member-block{
  .red-heading{
    h4{
      color: $xdark-red;
      font-family: $main-font;
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }
  .member-address{
    p{
      font-size: 14px;
      line-height: 20px;
      color: $lightest-gray;
      font-family: $open-sans;
    }
  }
}

/*========== council-block css here ==============*/
.council-block{
  .red-heading{
    h4{
      color: $xdark-red;
      font-family: $main-font;
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }
  .member-name{
    p{
      font-size: 14px;
      line-height: 20px;
      color: $lightest-gray;
      font-family: $open-sans;
    }
  }
}
