// ==========================================================================
//  FRAMEWORK VARIABLES
// ==========================================================================
$xs_breakpoint: 320px;
$sm_breakpoint: 500px;
$md_breakpoint: 768px;
$tablet_breakpoint: 992px;
$lg_breakpoint: 1050px;
$xl_breakpoint: 1200px;
$navbreakpoint: 768px;
$maxwidth: 1110px; //container max-width
//border radius
$input-radius: 2px;
$button-radius: 4px;
$input-secondary__radius: 6px; //grid gutters
// ==========================================================================
//  COLORS
// ==========================================================================
$blue: #169BD5;
$checkbox-bg: #308AF7;
$medium-blue-link: #53B2E2;
$popup-text-color: #5DB9D1;
$light-blue: #455C79;
$dark-gray: #003C55;
$dark-red: #840023;
$xdark-red: #98012E;
$light-red: #2B0913;
$paragraph-color: #200810;
$video-bg-color: #F0F0F0;
$xlight-blue: #E9F3F7;
$medium-red: #C03726;
$darker-red: #d33926;
$yellow: #FFD244;
$white: #ffffff;
$light-gold: #CCB68D;
$gray: #4C4D4F;
$light-gray: #2E2E2E;
$lightest-gray: #5E5F61;
$medium-gray: #F4F4F4;
$xmedium-gray: #D8D8D8;
$lighter-gray: #FAFAFA;
$image-bg-color: #86d0fd;
$darkest-gray: #9B9B9B;
$xlight-gray: #646464;
$sub-label-color: #959595;
$black: #000000;
$dark-black: #181818;
$light-black: #32373D;
$medium-black: #979797;
$dark-green: #24AB48;
$light-green: #00BB9A;
$dark-brown: #281A19;
$xlight-black: #2D2D2D;
$lightest-black: #3D3D3D;
$medium-blue: #52B5C4;
$light-brown: #663936;
$medium-brown: #656565;
$border-color: #C1C1C1;
$border-radio-btn: #CBCBCB;
$input-border-color: #D0D0D0; // ABSTRACTED COLORS
$primary-color: $black;
$secondary-color: $yellow;
$headline-font-color: #282828;
$body-font-color: #666666;
$sidebar-bg: #F9F9F9;
$icons-color: #B8B8B8;
$placeholder-color: #9C9C9C;
$blog-border-color: #D5D5D5;
$copyright-color: #636363;
$label-color: #656565;
$link-font-color: $primary-color;
$dark-yellow: #FFFF3A;
$yellow-color: #EA9842;
$orange-color: #D86D3F;
$shadow-color: rgba(0,0,0,0.07);
$row-bg-color: #f7f6f6;
$popup-content-bg: #EDEFF1;
$popup-header-bg: #BCC3CA;
$gray-dark-lighter: #E0E0E0;
$black-lighter: #5b5b5b;
$lighter-blue: #49A9DE;
$title-font-color: #797979;
$form-border-color: #f2f2f2;
$anchor-color: #53B2E2;
$search-icon-color: #C5C5C5;
$disable-button-bg: #dddddd;
$disable-button-color: #9b9b9b;
$gray-bg-color: #F7F7F7;
$slider-button-color: #48C6E2;
// ==========================================================================
//  FONTS
// ==========================================================================
@font-face {
    font-family: "Rajdhani";
    src: url("fonts/Rajdhani-Regular.eot"); /* IE9 Compat Modes */
    src: url("fonts/Rajdhani-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("fonts/Rajdhani-Regular.otf") format("opentype"), /* Open Type Font */
      url("fonts/Rajdhani-Regular.svg") format("svg"), /* Legacy iOS */
      url("fonts/Rajdhani-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
      url("fonts/Rajdhani-Regular.woff") format("woff"), /* Modern Browsers */
      url("fonts/Rajdhani-Regular.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }
$main-font: 'Rajdhani', Helvetica, sans-serif; // 300, 400
$open-sans: 'Open Sans', Helvetica, sans-serif;
$body-font-family: $main-font;
$headline-font-family: $main-font;
$button-font-family: $main-font; //
