/*======= sub text css ========*/
.sub-text{
  @media screen and (min-width: $md_breakpoint){
    padding: 11px 0 13lopx;
  }
  .fl-module-content{
    .fl-rich-text{
      p{
        color: $light-gold;
        font-size: 26px;
        line-height: 35px;
        font-weight: 500;
        letter-spacing: 1px;
        font-family: $main-font;
      }
    }
  }
}


/*============= image-content-section ==================*/
.image-content-section{
  .content-section{
    background-color: $white;
    -webkit-box-shadow: 0 2px 19px 0 $shadow-color;
    -moz-box-shadow: 0 2px 19px 0 $shadow-color;
    -ms-box-shadow: 0 2px 19px 0 $shadow-color;
    -o-box-shadow: 0 2px 19px 0 $shadow-color;
    box-shadow: 0 2px 19px 0 $shadow-color;
    padding: 42px 39px 0 33px;
    .fl-module-content{
      .fl-rich-text{
        h2{
          font-size: 60px;
          line-height: 72px;
          color: $light-gold;
          font-weight: 300;
          letter-spacing: 2px;
          font-family: $main-font;
          margin-top: 0;
          margin-bottom: 39px;
        }
        p{
          margin-bottom: 49px;
        }
        ul{
          padding-left: 55px;
          li{
            list-style-type: none;
            margin-bottom: 30px;
            a{
              text-decoration: none;
              font-size: 22px;
              line-height: 27px;
              font-family: $main-font;
              color: $gray;
              font-weight: 500;
              display: block;
              position: relative;
              padding: 10px 0;
              &:hover,
              &.active,
              &:focus,
              &:active,
              &:visited{
                color: $dark-red;
                &::before{
                  content: "";
                  border-top: 3px solid $dark-red;
                  position: absolute;
                  width: 47px;
                  top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*============= up-next-section ===========*/
.up-next-section{
  padding-top: 87px;
  padding-bottom: 130px;
  .fl-module-content{
    .fl-rich-text{
      p{
        strong{
          font-size: 26px;
          line-height: 31px;
          font-weight: 500;
          color: $dark-red;
          letter-spacing: 1px;
          font-family: $main-font;
        }
      }
      h2{
        a{
          font-size: 60px;
          line-height: 72px;
          color: $light-gold;
          font-weight: 300;
          letter-spacing: 2px;
          font-family: $main-font;
          &:hover,
          &.active,
          &:focus,
          &:active,
          &:visited{
            color: $dark-red;
          }
        }

      }
    }
  }
}

.project-section{
  .fl-row-content-wrap{
    background-position: 0%;
    background-size: contain;
    padding: 81px 0 58px 95px;

  }
}
/*=========== banner-heading css ==========*/
.banner-heading{
  h1{
    color: $white;
    font-weight: 300;
    letter-spacing: 2px;
    font-family: $main-font;
  }
}
