/* Search Style */

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  display: none;
  z-index: 999999;
  &.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -moz-transform: translate(0px, 0px) scale(1, 1);
    -ms-transform: translate(0px, 0px) scale(1, 1);
    -o-transform: translate(0px, 0px) scale(1, 1);
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    z-index: 999999;
    display: block;
  }
  input[type="search"] {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -51px;
    width: 50%;
    margin-left: 25%;
    background: $white url("/wp-content/themes/mission-eurasia-theme/images/Search-Icon.png")no-repeat scroll center right 10px / 20px 20px;
    border: 1px solid $white;
    box-shadow: 0 2px 15px 0 rgba(0,0,0,0.5);
    font-size: 40px;
    font-weight: 300;
    outline: none;
    padding: 25px 15px;
    border-radius: 0;
    font-size: 16px;
    font-family: $open-sans;
    color: #2B0913;
    z-index: 999999;
  }
  .close {
    position: absolute;
    top: 37.5%;
    right: 48%;
    opacity: 1;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    font-family: $open-sans;
    font-weight: 100;
    padding-right: 35px;
    background: transparent url("/wp-content/themes/mission-eurasia-theme/images/close.png")no-repeat scroll center right / 24px 24px;
    z-index: 999999;
    &:hover {
      color: $white;
      cursor: pointer;
    }
  }
}
.search__icon {
  font-size: 27px;
  outline: none !important;
  width: auto;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  img {
    width: 25px !important;
  }
  &:hover {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
}

.search-popup-enabled .what-we-do-wrapper {
  z-index: -2;
}

.tophead-search {
  margin-bottom: 80px;
  .searchpage-search {
    margin-bottom: 20px;
    input {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
      background: #FFFFFF url("/wp-content/themes/mission-eurasia-theme/images/Search-Icon.png") no-repeat scroll center right 10px / 20px 20px;
      border: 1px solid #979797;
      width: 320px;
      height: 39px;
      color: #5E5F61;
      font-family: $open-sans;
      font-size: 16px;
      line-height: 24px;
      border-radius: 1px;
      padding-right: 34px;
    }
  }
}

.searchpage-content {
  article {
    border-bottom: 1px solid #5E5F61;
    padding: 60px 0 20px;
  }
  header a {
    font-family: $main-font;
    font-size: 26px;
    color: #98012E;
    text-decoration: none !important;
    letter-spacing: 1px;
    line-height: 31px;
    margin-bottom: 5px;
    display: inline-block;
  }
  .fl-post-content {
    p {
      font-family: $open-sans;
      font-size: 16px;
      line-height: 24px;
      color: #2B0913;
    }
    a {
      font-family: $open-sans;
      color: #98012E;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
