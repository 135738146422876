p{
  font-size: 16px;
  line-height: 24px;
  color: $paragraph-color;
  font-weight: 400;
  font-family: $open-sans;
}
h1,h2,h3,h4,h5,h6{
  font-family: $main-font;
  font-weight: 300;
  letter-spacing: 2px;
}
h1{
  font-size: 60px;
  line-height: 64px;
}
ul{
  li{
    font-family: $main-font;
    font-size: 22px;
    line-height: 27px;
    list-style-type: none;
  }
}
a{
  text-decoration: none;
  color: $light-gold;
  @include transition(.3s);
  &:hover,
  &:visited,
  &:active,
  &:focus{
    color: $dark-red;
  }
}

img{
  @include image-fit(cover);
}
