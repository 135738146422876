.page-heading .fl-heading {
  font-weight: 300;
  color: #D6C29E;
  font-family: $main-font;
  font-size: 60px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 72px;
  text-align: center;
  cursor: none;
  pointer-events: none;
}

.story-title .fl-heading {
  color: #FFFFFF;
  font-family: $main-font;
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 46px;
}

.story-block {
  position: relative;
}

.story-title {
  position: absolute;
  bottom: 15%;
  left: 50%;
  -webkit-transform: translate(-50%, -15%);
  -moz-transform: translate(-50%, -15%);
  -ms-transform: translate(-50%, -15%);
  -o-transform: translate(-50%, -15%);
  transform: translate(-50%, -15%);
  .fl-module-content {
    margin: 0;
  }
}

.eurasia-programs .fl-rich-text {
  h4 {
    color: #98012E;
    font-family: $main-font;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 31px;
    text-align: center;
    margin-bottom: 17px;
  }
  p {
    color: #2B0913;
    font-family: $open-sans;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .btn-secondary {
    color: #98012E;
    font-family: $main-font;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    border: 1px solid #98012E;
    width: 239px;
    height: 55px;
    display: inline-block;
    margin-top: 23px;
    padding-top: 14px;
    padding-bottom: 14px;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.story-image .fl-module-content .fl-photo-content {
  background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
  background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
  background: -ms-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
  background: -o-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
}

.send-email-wrapper .fl-module-content .fl-rich-text {
  strong {
    color: #972145;
    font-family: $main-font;
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
  }
  p {
    color: #2B0913;
    font-family: $open-sans;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    margin-bottom: 2px;
  }
}

.other-way-blocks .fl-module-content {
  .fl-rich-text {
    h4 {
      color: #790000;
      font-family: $main-font;
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      text-align: center;
    }
    p {
      color: #2B0913;
      font-family: $open-sans;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
    a {
      color: #000000;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      text-align: center;
      text-decoration: none;
    }
  }
  border: 1px solid #BFB5B8;
  padding: 20px;
}

.donate-form .gform_wrapper form {
  .gform_title {
    display: none;
  }
  .gform_body {
    .gfield_label {
      color: #000000;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
    }
    .ginput_container_radio ul li {
      display: inline-block;
      label {
        color: #000000;
        font-family: $open-sans;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
  .gf_page_steps {
    border-bottom: 0;
  }
}

body.home header {
  position: relative;
  z-index: 1;
  .menu-topbar .fl-row-content-wrap, .header-main .fl-row-content-wrap {
    background-color: transparent;
  }
}

.banner-content .fl-module-content {
  width: 100%;
  .fl-rich-text {
    h1 {
      color: #D6C29E;
      font-family: $main-font;
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 18px;
    }
    p {
      color: #180007;
      font-family: $open-sans;
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 41px;
    }
    a {
      background-color: #98012E;
      text-decoration: none;
      width: 195px;
      text-align: center;
      color: #FFFFFF;
      font-family: $main-font;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 22px;
      text-align: center;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
      padding: 13px;
      margin-top: 37px;
      &:hover {
        color: #98012E;
        background: transparent;
        border: 1px solid #98012E;
      }
    }
  }
}

.fl-theme-builder-header-scrolled.fl-theme-builder-header-shrink {
  background-color: #ffffff;
}

.text-center {
  text-align: center;
}

.get-involved-wrapper {
  width: 100%;
  clear: both;
  .get-involved-content {
    img {
      margin-bottom: 15px;
    }
    float: left;
    width: 33%;
    min-width: 165px;
    margin-bottom: 60px;
    cursor: pointer;
    height: 111px;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -ms-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    a {
      text-decoration: none !important;
    }
    &:hover {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
      span {
        display: inline-block;
        margin-top: 10px;
      }
    }
    strong {
      color: #98012E;
      font-family: $main-font;
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 31px;
      text-align: center;
      display: block;
    }
    span {
      color: #98012E;
      font-family: $main-font;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 20px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      display: none;
      margin-top: 8px;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      position: relative;
      z-index: 9;
      &::before {
        content: '';
        background-color: #F2F4F7;
        width: 110%;
        height: 13px;
        left: -6px;
        top: 10px;
        position: absolute;
        z-index: -9;
      }
    }
  }
  .fl-row-content-wrap {
    background-size: 68% 100%;
    padding-top: 80px;
    padding-bottom: 150px;
  }
}

.other-way-wrapper {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
  -ms-box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
  -o-box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
  padding: 36px 24px 0 37px;
}

.what-we-do-wrapper {
  position: relative;
  z-index: 2;
  .fl-row-content-wrap {
    background-size: 70% 100%;
    padding-top: 75px;
    padding-bottom: 80px;
  }
  .content-section {
    ul li a {
      color: #5E5F61;
    }
    .fl-module-content .fl-rich-text ul li a {
      &:hover, &.active {
        color: #98012E;
      }
    }
    position: relative;
    z-index: 3;
  }
}

.what-we-do-img {
  padding-top: 20px;
  padding-bottom: 20px;
}

.what-we-do-image .inner-images li {
  -webkit-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  -ms-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  &.show {
    z-index: 1;
  }
}

.map-dots-wrapper {
  position: relative;
  z-index: 2;
  height: 200px;
  > a {
    &.map-dots {
      background-color: #98012E;
      height: 22px;
      width: 22px;
      border: 1px solid #F6F6F6;
      -webkit-box-shadow: 0 0 19px 0 rgba(43, 9, 19, 0.12);
      -moz-box-shadow: 0 0 19px 0 rgba(43, 9, 19, 0.12);
      -ms-box-shadow: 0 0 19px 0 rgba(43, 9, 19, 0.12);
      -o-box-shadow: 0 0 19px 0 rgba(43, 9, 19, 0.12);
      box-shadow: 0 0 19px 0 rgba(43, 9, 19, 0.12);
      display: block;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      border-radius: 100%;
      position: absolute;
      &.opacity {
        opacity: .5;
      }
      &.active {
        opacity: 1;
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
        -ms-transform: scale(0.7);
        -o-transform: scale(0.7);
        transform: scale(0.7);
      }
    }
    &:nth-child(1) {
      right: 37%;
    }
    &:nth-child(2) {
      top: 12%;
      left: 24%;
    }
    &:nth-child(3) {
      top: 50%;
      left: 26%;
    }
    &:nth-child(4) {
      top: 30%;
      left: 27%;
    }
    &:nth-child(5) {
      top: 38%;
      left: 50%;
    }
    &:nth-child(6) {
      top: 75%;
      left: 50%;
    }
    &:nth-child(7) {
      top: 62%;
      left: 50%;
    }
    &:nth-child(8) {
      left: 42%;
      top: 70%;
    }
    &:nth-child(9) {
      left: 38%;
      top: 72%;
    }
    &:nth-child(10) {
      top: 55%;
      left: 36%;
    }
    &:nth-child(11) {
      top: 100%;
      left: 32%;
    }
    &:nth-child(12) {
      right: 23%;
      top: 38%;
    }
    &:nth-child(13) {
      top: 63%;
      right: 39%;
    }
    &:nth-child(14) {
      top: 78%;
      right: 40%;
    }
  }
  &::after {
    content: "Eurasia";
    position: absolute;
    z-index: -1;
    opacity: 0.72;
    color: #EBEBEB;
    font-family: $main-font;
    font-size: 90px;
    font-weight: 300;
    letter-spacing: 3px;
    line-height: 108px;
    top: 0;
    right: 23%;
  }
}

.page-list li a.active:before {
  content: "";
  border-top: 3px solid #840023;
  position: absolute;
  width: 47px;
  top: 0;
}

.country-image-list {
  padding-left: 0;
  position: relative;
  > li {
    max-height: 350px;
    max-width: 644px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    > {
      a {
        position: absolute;
        bottom: 30px;
        left: 20px;
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        z-index: 1;
        padding-left: 7px;
        padding-right: 7px;
        display: none;
        &::after {
          content: "";
          background-color: #840023;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 15px;
          left: 0;
          right: 0;
          display: block;
          top: 12px;
        }
      }
      img {
        -webkit-object-fit: cover;
        -moz-object-fit: cover;
        -ms-object-fit: cover;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-height: 430px;
      }
    }
  }
  li {
    display: none;
    &.active {
      z-index: 1;
      display: block;
      box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
    }
  }
}

.map-dots-wrapper > .map-dots.active > .pluse {
  border: 4px solid #F6F6F6;
  -webkit-border-radius: 30px;
  background-color: #98012E;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.2);
  height: 30px;
  width: 30px;
  position: absolute;
  left: -5px;
  top: -5px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

@-moz-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}


@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}


.map-wrapper {
  min-height: 700px;
  max-height: 1000px;
  height: 700px;
  height: 100%;
}

.click-to-learn .fl-rich-text p {
  color: #675960;
  font-family: $open-sans;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.next-arrow, .prev-arrow {
  position: absolute;
  margin-top: 73px;
  z-index: 9999;
}

.next-arrow {
  right: 10px;
}

.prev-arrow {
  left: 10px;
}

.next-arrow > .fa, .prev-arrow > .fa {
  color: #200810;
  font-size: 52px;
  line-height: 56px;
}

@media screen and (max-width: 1100px) {
  .get-involved-wrapper .get-involved-content {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  /*===== home page banner css ======*/
  .banner-content .fl-module-content {
    width: auto;
    .fl-rich-text {
      text-align: center;
      a {
        margin-top: 120px;
      }
    }
  }
  .get-involved-image .fl-photo-content img {
      object-fit: cover;
      object-position: top;
  }
  .home-page-banner .fl-row-content-wrap {
    padding-bottom: 40px;
    padding-top: 0;
  }
  /*===== get involved section  css ======*/
  .get-involved-image {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 50px;
    .fl-module-content {
      margin-left: 0;
      margin-right: 0;
    }
    .fl-photo-content img {
      max-height: 300px;
    }
  }
  .other-way-wrapper {
    padding: 50px 0px 10px;
    max-width: 100%;
    margin-top: -70px;
    > .fl-module-content {
      margin: 10px;
    }
  }
  .get-involved-wrapper {
    .get-involved-content {
      margin-bottom: 40px;
    }
    .fl-row-content-wrap {
      background-size: cover;
      padding-top: 65px;
      padding-bottom: 100px;
    }
  }
  .what-we-do-wrapper .what-we-do-img {
    display: none;
  }
  .image-content-section .content-section {
    padding: 42px 5px 0 5px;
    .fl-module-content .fl-rich-text ul {
      padding-left: 0;
    }
  }
  .country-image-list {
    > li {
      max-height: 227px;
      min-height: 227px;
      overflow: hidden;
      width: 100%;
      max-width: 315px;
      width: 315px;
      margin-right: 22px;
      position: static;
    }
    &.slick-slider .slick-slide > {
      h1 {
        position: absolute;
        margin-left: 19px;
        color: #fff !important;
        text-transform: uppercase;
        z-index: 9;
        width: auto;
        height: auto;
        top: auto;
        display: block;
        background: transparent;
        bottom: 44px;
        left: auto;
        padding: 0;
        font-size: 28px;
      }
      a {
        position: absolute;
        bottom: 24px;
        margin-left: 19px;
        color: #fff !important;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 22px;
        z-index: 9;
        display: block;
      }
    }
  }
  .map-wrapper {
    height: 800px;
    max-height: 1000px;
  }
  .next-arrow, .prev-arrow {
    display: block;
    margin-top: 0;
    top: 75%;
  }
  .country-image-list > li > a {
    display: block;
  }
  .click-to-learn {
    display: none;
  }
  .country-image-list {
    max-height: 230px;
    &.slick-slider {
      .slick-track, .slick-list {
        max-height: 230px;
      }
      .slick-slide {
        max-height: 230px;
        > a::before {
          content: "";
          background-color: #840023;
          position: absolute;
          z-index: -9;
          width: 110%;
          height: 13px;
          left: -5px;
          right: 0;
          display: block;
          top: 12px;
        }
      }
    }
  }
  .country-img-wrapper .fl-module-content {
    margin-left: 0;
    margin-right: 0;
    max-height: 250px;
    height: 250px;
  }
}

@media screen and (min-width: 768px) {
  .get-involved-image {
    .fl-module-content {
      margin-right: 0;
    }
    .fl-photo-content {
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      img {
        width: 100%;
        height: auto;
        -webkit-object-fit: cover;
        -moz-object-fit: cover;
        -ms-object-fit: cover;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
  }
  .what-we-do-img .fl-module-content {
    margin-right: 0;
    .what-we-do-image ul {
      position: relative;
      li {
        position: absolute;
        width: 100%;
        img {
          width: 100%;
          height: 600px;
          -webkit-object-fit: cover;
          -moz-object-fit: cover;
          -ms-object-fit: cover;
          -o-object-fit: cover;
          object-fit: cover;
          -webkit-object-position: center;
          -moz-object-position: center;
          -ms-object-position: center;
          -o-object-position: center;
          object-position: center;
        }
      }
    }
  }
}

/**** header css *****/

.top--header--search {
  position: relative;
}
.mobile-menu {
  display: none !important;
}
.donate a span {
  background-color: #98012E;
  font-size: 18px;
  font-family: $main-font;
  color: #FFFFFF;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 1px;
  text-align: center;
  padding: 9px 23px;
  border: 1px solid #98012E;
  &:hover {
    color: #98012E;
    background: transparent;
    border: 1px solid #98012E;
  }
}

header.fl-theme-builder-header-shrink {
  box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.5);
}

.fl-theme-builder-header-shrink .header-logo .fl-photo-img-png img {
  width: auto !important;
}

.main-menu ul li a {
  font-family: $main-font;
  text-transform: uppercase !important;
  font-size: 22px !important;
  text-decoration: none !important;
  outline: none;
  line-height: 23px;
  font-weight: 300;
}

.top__header--menu ul li a {
  text-decoration: none !important;
}

.main-menu ul li ul li a {
  font-size: 19px !important;
  color: #200810 !important;
  font-weight: 300;
  line-height: 20px;
  text-transform: capitalize !important;
}

/**** footer ****/

footer .fl-heading {
  font-family: $main-font;
}

.footer--address ul li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
  font-family: $open-sans;
  &::after {
    content: "|";
    margin-left: 15px;
  }
  &:last-child::after {
    display: none;
  }
  a {
    color: #fff !important;
    text-decoration: none !important;
  }
}

.footer--signUp .gform_wrapper {
  margin: 0;
  form {
    display: flex;
    -webkit-display: flex;
    -moz-display: flex;
    flex-direction: row;
    font-family: $main-font;
    .gform_body {
      width: 60% !important;
      margin: 0;
      padding: 0;
    }
    .gform_footer {
      width: 30%;
      margin: 8px 0 0 0;
      padding: 0;
      text-align: left;
      input.gform_button {
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }
}

.social-icon .fl-icon-group-center span.fl-icon a i {
  color: #5A031C;
  width: 31px !important;
  height: 31px !important;
  display: inline-block;
  border-radius: 100%;
  background: #fff;
  font-size: 18px !important;
  line-height: 31px;
}

.footer--copyright p {
  color: #ffffff;
  font-size: 13px;
  font-family: $open-sans;
}

/***** what we do pub page *****/

.pub__distributing {
  word-break: break-word;
  h1 {
    font-size: 38px;
    font-family: $main-font;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 46px;
    color: #840023;
    margin-bottom: 20px;
  }
  ol {
    li {
        font-family: $open-sans;
        color: #200810;
        font-size: 16px;
        line-height: 24px;
        list-style: disc;
        margin-bottom: 20px;
      a {
        color: #98012E !important;
      }
    }
  }
  h3 {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 31px;
    color: #840023;
    font-family: $main-font;
    margin-bottom: 20px;
  }
  p {
    strong {
      color: #98012E !important;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
      font-family: $open-sans;
      margin-top: 20px;
      display: inline-block;
      text-transform: uppercase;
    }
    font-size: 16px;
    color: #200810;
    line-height: 24px;
    font-family: $open-sans;
    margin-top: 10px;
    a {
      color: #232323 !important;
      line-height: 20px;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 16px;
      i {
        vertical-align: sub;
        margin-right: 5px;
      }
    }
  }
}

.pub-donation-btn a {
  border: 1px solid #98012E !important;
  background-color: transparent !important;
  padding: 16px 50px !important;
  &:hover {
    background-color: #98012E !important;
    color: #fff !important;
    span {
      color: #fff !important;
    }
  }
}

.donate-now-by-project a {
  padding: 16px 20px !important;
}

.pub-donation-btn a span {
  color: #98012E !important;
  font-family: $main-font;
  font-weight: 300;
}

.pub__distributing ul {
  padding-left: 20px;
  li {
    font-family: $open-sans;
    color: #200810;
    font-size: 16px;
    line-height: 24px;
    list-style: disc;
    margin-bottom: 20px;
  }
}

.armenia-body-third-content-heading h3 span.fl-heading-text {
  font-weight: 300;
  font-family: $main-font;
}

.armenia-content .fl-rich-text h4 {
  font-family: $main-font;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  color: #841136;
}

.country-content-link a {
  font-family: $open-sans;
  color: #840023;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
}

.book-img img {
  margin-right: -20px;
}

.download-book {
  background-color: #F5F6F7;
  padding: 60px 20px 60px 50px;
  h1 {
    font-size: 38px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 46px;
    color: #D6C29E;
    margin-bottom: 10px;
    font-family: $main-font;
  }
  ul {
    padding-left: 0;
    li {
      display: inline-block;
      margin-right: 40px;
      font-family: $open-sans;
      a {
        color: #2B0913 !important;
        text-decoration: none !important;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.single-submenu-list ul {
  width: 250px;
  background: #fff;
  border-top: 8px solid #972145 !important;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  padding: 15px !important;
  z-index: 999 !important;
}

.two-submenu-list ul {
  width: auto !important;
  background: #fff;
  border-top: 8px solid #972145 !important;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  -webkit-column-count: 2;
  column-count: 2;
  -moz-column-count: 2;
  padding: 15px !important;
  z-index: 999 !important;
}

/****where we serve */

.country-img-section {
  .fl-row-content-wrap {
    background-position: top -42px right -22px !important;
  }
  .fl-col-small {
    position: relative;
  }
}

.country-caption {
  position: absolute;
  z-index: 99;
  color: #fff !important;
  width: calc(100% - 20px);
  height: 100%;
  top: 0px;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.13);
  right: 20px;
  left: 10px;
  bottom: 20px;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, #000000 90%);
  p {
    font-size: 38px;
    color: #FFFFFF;
    letter-spacing: 1px;
    margin-left: 20px;
    font-family: $main-font;
    margin-bottom: 25px;
    font-weight: 300;
    a {
      color: #FFFFFF !important;
      text-decoration: none !important;
    }
  }
}

.country-img img {
  height: 280px !important;
  object-fit: cover;
  object-position: center;
}

/***** where we serve page **/

.tabination {
  background-color: #FBFBFB;
  box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.5);
}

.tab-active h4 a {
  position: relative;
  &::after {
    content: '';
    width: 50px;
    height: 3px;
    background: #98012E;
    display: block;
    margin: 5px auto 0;
  }
}

.tab-info h4 a {
  color: #2B0913;
  font-family: $open-sans;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none !important;
}

.where-we-serve-map .map-dots-wrapper::after {
  right: 8%;
}

/**** header mobile code ****/

.pp-advanced-menu-accordion-collapse .pp-menu-close-btn {
  display: none !important;
}

/**** changed lives ***/

.country-filter .changed-lives-filter {
  .acf-country-dropdown {
    text-align: right;
  }

  button {
    background-repeat: no-repeat !important;
    background-position: right 20px top 17px !important;
  }

  select {
    padding: 9px 35px 9px 20px;
    border: 1px solid #5E5F61;
    height: 39px;
    width: 250px;
    font-family: $open-sans;
    font-size: 14px;
    text-align: center;
    background-color: transparent;
    background-image: url("/wp-content/uploads/2018/01/down-icon.png");
    background-repeat: no-repeat !important;
    background-position: right 20px top 17px;
    background-size: 11px 6px;
    background-clip: border-box;
    border-radius: 21px 21px 21px 21px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    outline: none !important;
    color: #323232;
  }
}

.map-text .fl-rich-text h1 {
  color: #EBEBEB;
  font-size: 90px;
  font-weight: 300;
  letter-spacing: 3px;
  opacity: 0.72;
  line-height: 108px;
  font-family: $main-font;
}

.main-menu .fl-module-content {
  margin-right: 0;
}

.menu-topbar .fl-row-content .fl-col-has-cols .fl-col-group {
  .fl-node-5a5da30497451 {
    width: 95% !important;
  }
  .fl-node-5a5da304974a5 {
    width: 5% !important;
  }
}

header.fl-theme-builder-header-shrink .header-logo .fl-module-content {
  margin-top: -5px !important;
}

body.home header {
  background: linear-gradient(0deg, rgba(245, 247, 246, 0) 0%, #F5F7F6 100%);
}
.other-way-blocks .fl-module-content {
  min-height: 235px;
  position: relative;
  padding-bottom: 40px;
  p a {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
  }
}

/** banner **/

.banner-img {
  position: relative;
  .fl-row-content-wrap {
    position: relative;
    z-index: 1;
  }
  .banner-heading {
    position: relative;
    z-index: 10;
  }
}

.fl-builder-edit .banner-img {
  .banner-heading, .fl-row-content-wrap {
    position: static;
  }
}

.where-we-serve-banner .fl-row-content-wrap::after {
  content: '';
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(270deg, rgba(198, 198, 198, 0) 0%, #C6C6C6 100%);
  display: none;
}

.what-we-do-banner .fl-row-content-wrap::after {
  content: '';
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(270deg, rgba(37, 37, 37, 0) 0%, rgba(17, 17, 17, 0.62) 100%);
  display: none;
}

.who-we-we-are-banner .fl-row-content-wrap::after {
  background: linear-gradient(270deg, rgba(55, 55, 63, 0) 0%, #37373F 100%);
  content: '';
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  position: absolute;
  display: none;
}

.children-ministry-banner .fl-row-content-wrap::after {
  content: '';
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(270deg, rgba(165, 119, 83, 0) 45%, #A57753 73%);
  display: none;
}

.changed-lives-banner .fl-row-content-wrap::after {
  content: '';
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(270deg, rgba(78, 78, 78, 0) 0%, rgba(87, 87, 87, 0.61) 100%);
  display: none;
}

/* who we are */

.content-block {
  &.what-we-believe .fl-module-content .fl-rich-text {
    ul {
      margin-top: 20px;
      li {
        color: #3C3C3C !important;
      }
    }
    h4 {
      margin-bottom: 0px;
      font-family: $open-sans;
      font-size: 16px;
      line-height: 24px;
      color: #2B0913;
    }
  }
  p a {
    font-family: $open-sans;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    margin-top: 10px;
    color: #98012E !important;
  }
}

.leadership-wrapper .fl-col-small .fl-module-content {
  .fl-photo-content, .fl-photo {
    width: 100%;
  }
  .fl-photo-content img.fl-photo-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
}

.up-next-section .fl-module-content .fl-rich-text h2 a {
  color: #CCB68D !important;
}

.country-image {
  .next-arrow, .prev-arrow {
    display: none;
  }
}

.up-next-section {
  padding-top: 60px;
  padding-bottom: 90px;
}

.content-article {
  .fl-post-grid-post {
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.07);
    background: #fff;
    border: none;
    padding: 7px;
    .fl-post-image img {
      width: 100%;
      max-width: 100%;
      height: 200px;
      object-fit: cover;
      object-position: center;
    }
    .fl-post-title {
      color: #CCB68D;
      font-size: 22px;
      font-family: $main-font;
      font-weight: 500;
      line-height: 32px;
    }
    .post-content {
      font-family: $open-sans;
      color: #200810;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .fl-post-more-link a {
      color: #840023 !important;
      text-decoration: none !important;
      font-family: $open-sans;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  .fl-builder-pagination {
    display: none;
  }
}

/* builder edit page */

.fl-builder-edit .country-image-list {
  overflow: hidden;
  height: 430px;
  > li {
    position: static;
  }
}

/* home changed lives section*/

.home-change-lives .type-storytitle {
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
  border: none !important;
  position: relative;
  transition: .3s ease;
  position: relative;
  &::after {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
  }
  .fl-post-text {
    position: absolute;
    bottom: 40px;
    width: 100%;
    padding: 0;
    z-index: 11;
  }
  &:hover .fl-post-text p a {
    display: block;
    margin-top: 10px;
  }
  .fl-post-text {
    h2.fl-post-title {
      font-size: 38px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 46px;
      font-family: $main-font;
      padding: 0 30px;
      text-transform: uppercase;
      a {
        color: #fff !important;
      }
    }
    p a {
      display: none;
      position: relative;
      z-index: 9;
      color: #fff;
      padding: 0 30px;
      font-family: $open-sans;
      text-decoration: none !important;
      &::before {
        content: '';
        height: 13px;
        width: 130px;
        background: #98012E;
        z-index: -9;
        position: absolute;
        top: 10px;
        left: 25px;
      }
    }
  }
}

.donate-locate img.alignnone {
  margin-bottom: 8px !important;
  margin-right: 8px !important;
}

/* thank you page */

.thanks-text .fl-rich-text {
  img {
    margin-bottom: 40px;
  }
  h2 {
    font-family: $open-sans;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 41px;
    color: #180007;
    margin-bottom: 52px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-family: $open-sans;
    color: #2B0913;
    margin-bottom: 70px;
    a {
      color: #2B0913 !important;
      text-decoration: underline;
      font-style: italic;
      display: inline-block;
      margin-left: 5px;
    }
  }
}

body {
  overflow-x: hidden !important;
  width: 100% !important;
  max-width: 100% !important;
}


.what-we-do-wrapper .content-section ul li a {
  color: #5E5F61 !important;
  &.active, &:hover {
    color: #840023 !important;
  }
}

.slick-arrow.slick-next {
  position: absolute;
  top: 44%;
  right: 0 !important;
  width: auto !important;
  height: auto !important;
  z-index: 999;
  outline: none !important;
}

.slick-prev {
  left: 0 !important;
  display: none !important;
}

.slick-next {
  right: 0 !important;
  &:before {
    content: "\f105" !important;
    font-family: FontAwesome;
    color: #2B0913;
    font-size: 60px;
    outline: none !important;
  }
}

.slick-current.slick-active ~ .slides.slick-slide {
  opacity: .5 !important;
}

.slick-slide img {
  transform: scale(0.95);
  box-shadow: 0 -3px 6px 0px rgba(0, 0, 0, 0.3);
  min-height: 245px;
}

.map-dots-wrapper > a.map-dots.active:hover {
  .map-dots-wrapper > a.map-dots {
    opacity: .5;
  }
  opacity: 1;
}

.country-img-wrapper {
  min-height: 430px;
}

.fullWidth-imageSection {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .fl-module-content, .fl-photo {
    width: 100%;
    height: 100%;
  }
  .fl-photo-content {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.donation-page-image {
  width: 100%;
  ul {
    padding: 0;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.donationpost {
  .fl-post-title, .fl-post-excerpt {
    width: 100% !important;
  }
}

.up-next-section .fl-module-content .fl-rich-text p strong {
  color: #98012E;
}

.country-next-section {
  padding-top: 13px;
  padding-bottom: 82px;
}

.change-life-next-section {
  padding-top: 0px;
  margin-top: -35px;
}

.country-next-section .fl-module-content .fl-rich-text p strong {
  color: #840023;
}

.sub-text .fl-module-content .fl-rich-text p {
  color: #D6C29E;
}

.up-next-section .fl-module-content .fl-rich-text h2 a {
  color: #D6C29E !important;
}

body.single {
  .fl-content-left {
    border-right: none !important;
    article {
      .fl-post-meta-bottom {
        border-top: none !important;
      }
      ul li {
        font-family: $open-sans;
        color: #200810;
        font-size: 16px;
        line-height: 24px;
        list-style: disc;
        margin-bottom: 20px;
      }
    }
  }
  .fl-post-meta, &.fl-post-author, &.fl-sep, .fl-post-date {
    display: none !important;
  }
}

.donate-post .fl-builder-pagination {
  display: none;
}

.hidden-section {
  visibility: hidden !important;
  position: absolute;
  top: 0;
}

.header-logo .fl-photo-img-png img {
  width: 109px !important;
  height: auto !important;
}

.donation-left {
  height: 100%;
  width: 100%;
  .fl-module-content {
    height: 100%;
    width: 100%;
    .fl-html, .donation-page-image {
      height: 100%;
      width: 100%;
    }
    ul.inner-images {
      height: 100%;
      width: 100%;
      li {
        height: 700px;
      }
    }
  }
}

.donatepage-text p {
  font-family: $open-sans;
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
  color: #5E5F61;
}

.donatepage-text-left h4 {
  font-family: $open-sans;
  font-size: 14px;
  font-style: italic;
  line-height: 19px;
  color: #5E5F61;
  font-weight: bold;
}

.grecaptcha-badge {
  display: none !important;
}

.country-img-wrapper .slides > {
  h1 {
    position: absolute;
    bottom: 0;
    padding: 20px 20px 40px;
    color: #fff !important;
    text-transform: uppercase;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%);
    top: 0;
    left: 0;
  }
  a {
    position: absolute;
    bottom: 24px;
    margin-left: 19px;
    color: #fff !important;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 22px;
    z-index: 9;
    display: none;
  }
}

.validation_message {
  font-family: $open-sans !important;
  font-size: 16px !important;
}

.fl-builder-edit .hidden {
  visibility: visible !important;
  opacity: 1 !important;
  display: block !important;
}

.home-map .fl-row-content-wrap {
  background-position: top center !important;
}

.single .fl-sidebar-right aside {
  h4.fl-widget-title {
    margin-bottom: 5px !important;
    color: #840023 !important;
    font-size: 20px;
    font-weight: 400;
  }
  select {
    width: 100% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    font-size: 16px;
    line-height: 21px;
    font-family: $open-sans;
    color: #000000;
    background-color: transparent;
    background-image: url(/wp-content/uploads/2018/01/down-icon.png);
    background-repeat: no-repeat;
    background-position: right 13px top 18px;
    background-size: 13px 8px;
    border: 1px solid #BFB5B8 !important;
    border-radius: 0;
    padding: 12px 10px !important;
    border-radius: 2px !important;
    outline: none !important;
  }
}

#archives, #categories {
  display: none !important;
}

.changedFilterProject, .located-in-canada {
  cursor: pointer;
}

.book-image .fl-photo-content img {
  box-shadow: -9px 15px 20px -3px rgba(0, 0, 0, 0.2);
}

.single-page-donate a {
  background-color: #98012E !important;
  border-color: #98012E !important;
  text-decoration: none;
  width: 195px !important;
  text-align: center;
  color: #FFFFFF;
  font-family: $main-font;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 22px;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  padding: 13px 50px;
  text-decoration: none !important;
  display: inline-block;
  margin: 20px 0 30px;
}
.single-page-donate a:hover {
  color: #98012E !important;
  background: transparent !important;
  border: 1px solid #98012E !important;
  span {
    color: #98012E !important;
  }
}

.pub__distributing .pp-gf-content {
  background: transparent !important;
  .gform_wrapper {
    .gform_heading {
      display: none !important;
    }
    .emmaEditDotBox {
      margin-bottom: 20px;
    }
  }
}
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
}

article.events header {
  display: none !important;
}

.pub__distributing.event-internal {
  p {
    font-family: $open-sans;
    strong {
      color: #2B0913 !important;
      a {
        color: #98012E !important;
        font-family: $open-sans;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
        font-weight: bold;
      }
    }
  }
  h2 {
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 41px;
    font-family: $open-sans;
    color: #180007;
  }
}

.event-grid .fl-builder-pagination {
  display: none !important;
}

.fl-post-grid-empty {
  text-align: center;
}
/**responsive **/
@media screen and (max-width: 1660px) {
  .donation-form .ginput_container_creditcard .ginput_cardinfo_left .ginput_card_expiration_container select {
    &.ginput_card_expiration_month {
      margin-right: 1.5% !important;
    }
    &.ginput_card_expiration_year {
      margin-left: 1.5% !important;
    }
  }
}

@media screen and (max-width: 1440px) {
  .content-right {
    margin-left: 50px;
  }
  .main-menu ul li a {
    font-size: 18px !important;
  }
  .pub__distributing.content-right .fl-module-content, .pub-donation-btn.content-right .fl-module-content {
    margin-right: 60px !important;
  }
  .click-to-learn .fl-module-content {
    margin-top: 0 !important;
  }
  .map-dots-wrapper {
    > a {
      &:nth-child(2) {
        top: 18%;
        left: 24%;
      }
      &:nth-child(3) {
        top: 48%;
        left: 24%;
      }
      &:nth-child(4) {
        top: 36%;
        left: 27%;
      }

    }
  }
}

@media screen and (max-width: 1366px) {
  .map-dots-wrapper {
    height: 180px;
  }
  .country-img-section {
    .fl-row-content-wrap {
      background-position: top 18px right -22px !important;
    }
  }
}

@media screen and (max-width: 1310px) {
  .main-menu ul li a {
    font-size: 15px !important;
    padding: 10px 7px !important;
  }
  .donate a span {
    padding: 9px 16px;
    font-size: 15px;
  }

  .top__header--menu ul li a {
    font-size: 15px !important;
  }
  .main-menu .pp-menu-toggle {
    right: 10px !important;
  }
  .what-we-believe .fl-module-content {
    margin-left: 20px !important;
  }
  .donationpost .fl-post-excerpt {
    height: 150px;
  }
  .donation-form .ginput_container_creditcard .ginput_cardinfo_left .ginput_card_expiration_container select {
    &.ginput_card_expiration_month {
      margin-right: 1.4% !important;
    }
    &.ginput_card_expiration_year {
      margin-left: 1.4% !important;
    }
  }
}

@media screen and (max-width: 1280px) {
  .map-dots-wrapper {
    margin-top: -20px;
  }
}

@media screen and (max-width: 1200px) {
  .donation-form .ginput_container_creditcard .ginput_cardinfo_left .ginput_card_expiration_container select {
    &.ginput_card_expiration_month, &.ginput_card_expiration_year {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .ginput_container_creditcard {
    .card_field, .security_field {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  #search input[type="search"] {
    margin-left: 0;
    width: 100%;
  }
  #search input[type="search"]::placeholder {
    color: #2B0913;
  }
  .country-img-section .fl-row-content-wrap {
    background-position: top 48px right -66px !important;
  }
  .menu-topbar {
    display: none;
  }
  header.fl-theme-builder-header-shrink .header-logo .fl-module-content {
    margin-top: 25px !important;
  }
  .main-menu .hamburger-label {
    margin-top: 25px;
  }
  html.pp-off-canvas-menu-open {
    .fl-page-content, footer {
      display: none;
    }
  }
  .pp-off-canvas-menu-module {
    overflow-x: hidden;
  }
  .header-main .hamburger-menu {
    background: url("/wp-content/uploads/2018/01/MENU-ICON@1-1.png") no-repeat scroll center (center / contain);
    width: 50px;
    height: 30px;
    position: relative;
  }
  .fl-hamburger-menu-top, .fl-hamburger-menu-middle, .fl-hamburger-menu-bottom {
    display: none;
  }
  .pp-advanced-menu-accordion-collapse .menu.pp-advanced-menu-horizontal li a {
    text-align: left !important;
    font-size: 27px !important;
    color: #fff !important;
    text-transform: uppercase;
  }
  .mobile-search {
    position: relative;
    form input {
      background: #480418 !important;
      height: 52px;
      border: none !important;
      font-size: 16px;
      &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
        display: none !important;
      }
    }
    &::after {
      content: "\f002";
      font-family: FontAwesome;
      position: absolute;
      top: 28%;
      right: 20px;
      color: #fff;
    }
  }
  .donate a span {
    display: block;
    width: 100%;
    padding: 18px 10px;
    font-size: 20px !important;
  }
  .mobile-menu {
    float: left;
    display: inline-block !important;
  }
  .pp-advanced-menu-accordion-collapse .menu.pp-advanced-menu-horizontal li.mobile-menu a {
    font-size: 20px !important;
    text-transform: capitalize !important;
  }
  .two-submenu-list ul, .single-submenu-list ul {
    background: transparent;
    border: none !important;
    -moz-column-count: 1;
    -webkit-column-count: 1;
    padding-left: 20px;
    width: auto !important;
  }
  .pp-advanced-menu-accordion-collapse .menu.pp-advanced-menu-horizontal li .sub-menu li a {
    font-size: 19px !important;
    text-transform: uppercase !important;
  }
  .pp-advanced-menu.off-canvas .pp-clear {
    background: transparent !important;
  }
  .header-main .pp-advanced-menu-mobile-toggle-label {
    margin-left: 0px !important;
    text-transform: uppercase;
    margin-top: 10px;
    color: #98012E;
    font-weight: 500;
    font-size: 16px;
    font-family: $main-font;
    margin-left: 40px !important;
  }
  .header-logo .fl-module-content {
    margin-top: 20px !important;
  }
  .armenia-body-content .fl-module-content {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .pub__distributing .fl-module-content {
    margin-left: 20px !important;
    margin-right: 40px !important;
  }
  .menu-item-text {
    position: relative;
  }
  .pp-menu-toggle {
    right: 0 !important;
    &::before {
      border-color: #fff !important;
      width: 12px !important;
      height: 12px !important;
      border-right: 3px solid !important;
      border-bottom: 3px solid !important;
    }
  }
  .mobile-search {
    display: block !important;
    padding: 0 10px !important;
  }
  .pattern-banner .fl-row-content-wrap {
    background-size: 50% 100% !important;
    background-position: top left !important;
  }
  .pub-donation-btn, .pub__distributing {
    width: auto !important;
  }
  .content-right {
    margin-left: 20px;
  }
  .social-icon .fl-module-content {
    margin: 20px 0 !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .home-map .fl-row-content-wrap {
    background-position: right -140px top  !important;
  }
  .map-dots-wrapper > a {
    &:nth-child(2) {
      top: 32%;
      left: 24%;
    }
    &:nth-child(3) {
      top: 50%;
      left: 24%;
    }
    &:nth-child(4) {
      top: 42%;
      left: 28%;
    }
    &:nth-child(6) {
      top: 80%;
      left: 66%;
    }
    &:nth-child(8) {
      left: 56%;
      top: 82%;
    }
    &:nth-child(9) {
      left: 40%;
      top: 86%;
    }
    &:nth-child(10) {
      top: 70%;
      left: 39%;
    }
    &:nth-child(11) {
      top: 108%;
      left: 32%;
    }
    &:nth-child(7) {
      left: 69%;
    }
    &:nth-child(13) {
      top: 58%;
      right: 17%;
    }
    &:nth-child(12) {
      right: 0;
    }
    &:nth-child(14) {
      right: 19%;
      top: 76%;
    }
  }
  .donate-locate .fl-module-content {
    margin-top: 14px !important;
  }
}

@media screen and (min-width: 992px) {
  .slick-track, .slick-next {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .header-main {
    .fl-row-content .fl-col-group.fl-node-5a5c92e0b05f3 {
      display: flex;
      flex-direction: row;
    }
    .fl-row-content-wrap {
      padding: 0 15px !important;
    }
  }
  .content-right {
    margin-left: 0 !important;
  }
  .footer-mission-img img {
    width: 25% !important;
  }
  .donation-left .fl-module-content {
    margin-right: 0 !important;
  }
  .pub-donation-btn .fl-button-left {
    text-align: left !important;
  }
  .pattern-banner .fl-row-content-wrap {
    background-size: 100% 19% !important;
    background-position: top center !important;
  }
  .book-img img {
    margin-right: auto;
  }
  #humanitarian-outreach, #mission-eurasia-religion, #summer-bible {
    margin-top: 75px !important;
  }
  .tab-info h4 {
    text-align: center !important;
  }
  .donationpost .fl-post-excerpt {
    height: 130px;
  }
}

@media screen and (max-width: 768px) {
  .footer--signUp .gform_wrapper form {
    display: block;
  }
  .pub__distributing article {
    margin: 20px 0 30px;
    img {
    	width: 100%;
    }
    .fl-button-wrap {
      margin-bottom: 30px;
    }
  }
  .fullWidth-imageSection .fl-module-content {
    width: 100%;
    height: 100%;
    margin: 0px !important;
  }
  .footer--address, .fl-node-5a5dadad54562 h4 {
    text-align: center;
  }
  .footer--signUp .gform_wrapper form .gform_footer {
    width: 100% !important;
    margin-top: 3px;
    display: block !important;
    input {
      width: 100% !important;
      font-family: $open-sans;
    }
  }
  .mobile-home-change-lives {
    .fl-post-carousel-post {
      position: relative;
    }
    .fl-post-carousel-image {
      img {
        height: 300px !important;
        object-fit: cover;
        object-position: center;
      }
    }
    .fl-post-carousel-text {
      position: absolute;
      bottom: 20px;
      width: 100%;
    }
    .fl-post-carousel-title {
      text-align: left;
      a {
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 40px;
        font-family: $main-font;
        padding: 0;
        text-transform: uppercase;
        color: #fff !important;
      }
    }
    .fl-post-carousel-content a {
      position: relative;
      z-index: 9;
      color: #fff;
      padding: 0 0;
      font-family: $open-sans;
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 22px;
      text-transform: uppercase;
      text-decoration: none !important;
      &:before {
        content: '';
        height: 13px;
        width: 130px;
        background: #98012E;
        z-index: -9;
        position: absolute;
        top: 10px;
        left: 16px;
      }
    }
    .bx-wrapper .bx-pager.bx-default-pager a {
      background: #fff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #5E5F61;
      &.active {
        background-color: #98012E;
        border: 1px solid #98012E;
      }
    }
  }

  .footer--signUp {
    .gform_wrapper form .gform_body {
      width: 100% !important;
      input {
        color: #fff !important;
      }
    }
    .fl-module-content {
      margin: 0 !important;
    }
  }
  .footer-partners .fl-node-5a5dcfac58f8d {
    padding: 20px;
    .fl-col-small {
      width: 50% !important;
      float: left !important;
      clear: initial !important;
      margin-bottom: 40px;
      &:last-child {
        width: 100% !important;
        text-align: center;
        float: none !important;
      }
    }
  }
  .fl-node-5a71bb7ce3761.fl-module-heading .fl-heading, .fl-node-5a66f7b171d73.fl-module-heading .fl-heading {
    font-size: 60px;
  }
  .get-involved-image .fl-photo-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .country-img-section {
    .fl-col {
      width: 100% !important;
      max-width: 100% !important;
    }
    .fl-row-content-wrap {
      padding: 0px !important;
    }
    .fl-col-small {
      margin-bottom: 20px;
    }
  }
  .country-img img {
    height: 380px !important;
  }
  .donate-locate .fl-rich-text h4 {
    text-align: center !important;
  }
  .gf_step {
    width: 80px !important;
  }
  #gf_step_2_2::after, .gf_step_active::after, .gf_step_completed::after {
    width: 54px;
  }
  #gf_page_steps_2 {
    text-align: center !important;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
  .gf_step_last {
    width: auto !important;
  }
  .what-we-do-wrapper .content-section ul li a {
    color: #5E5F61 !important;
    &.active, &:hover {
      color: #5E5F61 !important;
    }
  }
  .banner-heading h1 {
    font-size: 40px !important;
  }
  .page-list li a.active:before {
    display: none !important;
  }
  .home-map .fl-row-content-wrap {
    background-position: right top 156px !important;
  }
  .map-dots-wrapper {
    > a {
      &:nth-child(2) {
        top: 10%;
        left: 24%;
      }
      &:nth-child(3) {
        top: 46%;
        left: 26%;
      }
      &:nth-child(4) {
        top: 30%;
    left: 28%;
      }

    }
  }
}

@media screen and (max-width: 767px) {
  .bx-viewport .fl-post-carousel-wrapper .fl-post-carousel-post {
    width: 310px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mobile-home-change-lives .fl-module-content {
    margin: 0 !important;
  }
  .country-img img {
    height: 300px !important;
  }
  .header-main .fl-row-content-wrap {
    padding: 0 0 !important;
  }
  .header-logo {
    margin-bottom: 15px;
  }
  .aspect-ratio {
    width: 100%;
    max-width: 100%;
    overflow: scroll !important;
    iframe {
      width: 100%;
      max-width: 100%;
      overflow: scroll !important;
    }
  }
  .main-menu .hamburger-label {
    margin-top: 5px;
  }
  .pp-advanced-menu-mobile-toggle {
    text-align: left !important;
  }
  .header-logo .fl-module-content {
    margin-left: 0;
  }

  .header-main {
    .fl-row-content-wrap .fl-node-content .fl-col-group {
      display: flex;
      .fl-col {
        &.fl-col-small {
          order: 2;
        }
        &.fl-node-5a5c92e0b071c {
          width: 0% !important;
          margin: 0 !important;
        }
      }
    }
    .pp-advanced-menu-mobile-toggle-label {
      margin-left: 0px !important;
    }
  }
  .footer--signUp .gform_wrapper form {
    flex-flow: column wrap;
  }
  .pp-advanced-menu-accordion-collapse .menu.pp-advanced-menu-horizontal {
    box-shadow: inset -4px 0 20px 0 #3F0114, 2px 2px 15px 0 rgba(58, 1, 18, 0.07);
    margin-top: 0;
    li {
      padding: 5px 10px;
    }
    > li {
      &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        border-bottom: 1px solid rgba(152, 1, 46, 0.5);
      }
    }
  }
  html.pp-off-canvas-menu-open {
    header, .fl-page-content {
      transform: translateX(84%) !important;
      transition: 0.5s ease;
    }
  }
  .main-menu .fl-module-content {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
  .country-image {
    .next-arrow, .prev-arrow {
      display: block;
    }
  }
  .content-right {
    margin-left: 20px;
  }
  .fl-col-small {
    width: 100% !important;
    max-width: 100% !important;
  }
  .header-logo .fl-photo-img-png {
    text-align: center !important;
    img {
      width: 135px !important;
      height: auto !important;
    }
  }
  .image-content-section .content-section {
    padding: 0px;
  }
  .banner-content .fl-module-content .fl-rich-text a {
    position: relative;
    z-index: 999;
  }
  .image-content-section .content-section .fl-module-content .fl-rich-text ul {
    padding-left: 0;
  }
  .what-we-do-wrapper .fl-row-content-wrap {
    padding-bottom: 0;
  }
  .fl-row[data-node] .fl-row-content-wrap {
    background-size: cover;
  }
  .map-dots-wrapper::after {
    display: none;
  }
  .footer--address {
    .fl-module-content {
      margin: 18px !important;
    }
    ul {
      padding-left: 0;
      text-align: center;
    }
  }
  .footer-partners {
    .fl-col-small {
      margin-bottom: 30px !important;
    }
    .fl-row-content-wrap, .fl-node-5a5dcfac58f8d {
      padding-bottom: 0;
    }
  }
  .footer--copyright .fl-module-content {
    margin-bottom: 0px !important;
  }
  .country-filter .changed-lives-filter {
    .acf-country-dropdown {
      text-align: center;
      margin-bottom: 20px;
    }
    .acf-project-dropdown {
      text-align: center;
    }
  }

  .pub__distributing.content-right .fl-module-content, .pub-donation-btn.content-right .fl-module-content, .pub__distributing .fl-module-content {
    margin-right: 20px !important;
  }
  .what-we-do-wrapper.image-content-section {
    position: relative;
    top: -75px;
  }
  .banner-content .fl-module-content {
    margin-top: 0 !important;
    .fl-rich-text a {
      width: 257px;
    }
  }
  .donate-post .fl-post-grid {
    margin-left: -15px !important;
    margin-right: -15px !important;
    .fl-post-column {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
  .donate-form .fl-module-content .ginput_container_select select.gfield_select {
    padding: 10px 12px !important;
  }
  .armenia-body-third-content-heading h3 span.fl-heading-text {
    word-break: break-word;
  }
  .map-wrapper {
    height: auto;
    max-height: 900px;
  }
  .fl-module-content h2 {
    text-align: center;
  }
  .donation-left .fl-module-content ul.inner-images li {
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  #search .close {
    font-size: 20px;
    top: 20px;
    right: 20px;
  }
  .footer-mission-img img {
    width: 40% !important;
  }

  .ginput_container_radio ul li {
    &.gchoice_2_2_0, &.gchoice_2_2_1, &.gchoice_2_2_2, &.gchoice_2_2_3 {
      width: 100%;
    }
  }
  .donate-form .fl-module-content {
    #field_2_17, #field_2_3 {
      width: 100%;
    }
  }
  #gform_page_2_2 .gform_page_footer .button#gform_next_button_2_15 {
    width: 100%;
  }
  .home-map .fl-row-content-wrap {
    background-position: 83% 380px !important;
    background-size: 280% !important;
  }
  .map-dots-wrapper {
    height: 150px;
    > a {
      &:nth-child(1) {
        right: 27%;
        top: 5%;
      }
      &:nth-child(2) {
        top: 17%;
        left: 20%;
      }
      &:nth-child(3) {
        top: 45%;
        left: 20%;
      }
      &:nth-child(4) {
        top: 34%;
        left: 25%;
      }
      &:nth-child(6) {
        left: 58%;
      }
      &:nth-child(7) {
        top: 60%;
        left: 62%;
      }
      &:nth-child(8) {
        left: 45%;
        top: 63%;
      }
      &:nth-child(9) {
        left: 35%;
        top: 66%;
      }
      &:nth-child(10) {
        top: 48%;
        left: 35%;
      }
      &:nth-child(11) {
        top: 88%;
        left: 30%;
      }
      &:nth-child(12) {
        right: 5%;
        top: 58%;
      }
      &:nth-child(13) {
        right: 20%;
      }
      &:nth-child(14) {
        right: 24%;
        top: 80%;
      }
    }
  }
}
.recurringThanksMsg span {
  text-decoration: underline;
}

.donate-post .fl-post-more-link a {
  color: #2B0913;
}

.gform_validation_error .gfield_creditcard_warning .ginput_container label {
  color: #000 !important;
}

#input_2_28 {
  font-family: $open-sans !important;
  font-size: 16px !important;
}

.recurring-months {
  padding-left: 35px;
}

.content-article .fl-post-grid {
  justify-content: center;
  display: flex;
}

.fullWidth-imageSection .fl-photo-content img {
  height: 100% !important;
}
#wufoo-z1jqdw2e11fwpva{
  margin-top: 40px;
  p a {
    font-weight: bold;
    font-family: $open-sans !important;
    color: #840023 !important;
    display: inline-block;
    &:hover {
      color: #ffffff !important;
      text-decoration: none;
    }
  }
}


.ngcl-page {
  p a {
    font-family: $open-sans !important;
    color: #840023 !important;
  }
  h1 {
    margin-bottom: 50px;
  }
  h3 {
    margin-bottom: 0 !important;
  }
  p {
    margin-top: 0;
  }
}

.pp-off-canvas-menu-open body {
  background: #fff !important;
}

.donate-canada-address p a {
  color: #2B0913 !important;
}

.custom-search-pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.open.dropdown-menu {
  bottom: auto !important;
  top: 95%;
  width: 100%;
  border-radius: 0 0 20px 20px;
  border: 1px solid #5E5F61;
  overflow: hidden;
}

.dropdown-menu > li > a {
  &:hover, &:focus {
    background-color: transparent !important;
    outline: none !important;
  }
}



.bs-caret {
  display: none !important;
}

.bootstrap-select {
  &:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: auto !important;
  }
  &.btn-group .dropdown-menu li a {
    white-space: normal !important;
    span.text {
      font-family: $open-sans;
      font-size: 14px;
      color: #2B0913 !important;
      padding: 5px 0 !important;
      display: inline-block;
    }
  }
}

.gform_confirmation_wrapper {
  font-size: 20px;
  font-family: $open-sans;
  color: #200810;
}

.emmaEditDotBox span {
  font-size: 16px;
  font-family: $open-sans !important;
}

.changed-life-page-filter-posts .fl-post-grid-empty {
  margin-bottom: 50px;
}

@media screen and (max-width: 1024px) {
  .tablet-dcountry {
    margin-bottom: -30px;
  }
  .fl-sidebar {
    display: block !important;
  }

  .fl-sidebar-right {
    padding-left: 20px !important;
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .fl-content {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 768px) {
  .member-block .fl-col-group {
    display: flex;
    flex-flow: row wrap;
  }
  .fullWidth-imageSection .fl-module-content {
    margin: 0 !important;
  }
  .member-block .fl-col-group .fl-col {
    width: 100% !important;
  }
  .council-block .fl-col-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .fl-col {
      width: 100% !important;
      .fl-rich-text p {
        text-align: center !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .content-article .fl-post-grid {
    justify-content: center;
    display: block;
  }
  .council-block .member-name p {
    text-align: center !important;
  }
  .bx-wrapper .bx-pager-item {
    margin: 0 6px;
  }
}

#search input[type="search"]::placeholder {
  color: #2B0913;
}

.footer--signUp {
  .gform_confirmation_wrapper {
    color: #fff !important;
    font-size: 16px;
    font-family: $open-sans !important;
  }
  .ginput_container_email input {
    font-family: $open-sans !important;
  }
}
.text-heading-section h2 {
	color: #D6C29E !important;
}
.fl-node-5a6188e2e966c > .fl-col-content {
	background: transparent !important;
}
.bootstrap-select.select-country button,
.bootstrap-select.select-project button,
.bootstrap-select.filterStyling button {
  padding: 9px 35px 9px 20px;
  border: 1px solid #5e5f61 !important;
  height: 39px;
  width: 250px;
  font-family: $open-sans;
  font-size: 14px;
  text-align: center !important;
  background-color: transparent !important;
  background-image: url(/wp-content/uploads/2018/01/down-icon.png) !important;
  background-repeat: no-repeat;
  background-position: right 20px top 17px;
  background-size: 11px 6px;
  background-clip: border-box;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  outline: none !important;
  color: #323232 !important;
  outline: none !important;
}
.bootstrap-select.select-country.open button,
.bootstrap-select.select-project.open button,
.bootstrap-select.filterStyling.open button {
  border-radius: 20px 20px 0px 0px;
  box-shadow: none !important;
}
.bootstrap-select.select-country button:focus,
.bootstrap-select.select-project button:focus,
.bootstrap-select.filterStyling button:focus {
  outline: none !important;
}
.bootstrap-select.btn-group .dropdown-menu li {
  text-align: center;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  text-align: center !important;
}
.select-country .dropdown-menu.open {
	top: 36px !important;
}
.pub__distributing article .fl-rich-text p strong {
	display: inline !important;
}
.pub__distributing article .fl-rich-text  strong {
	display: inline !important;
}

.single .fl-sidebar-right aside select, ul.dropdown-menu>li.selected {
	display: none !important;
}
