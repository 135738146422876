/** donate page **/
.donate-form {
  .fl-module-content {
    margin-top: 0 !important;
    .pp-gf-content {
      padding-top: 0 !important;
    }
    .gform_heading {
      display: none;
    }
    .gf_browser_chrome.gform_wrapper {
      margin-top: 0 !important;
    }
  }
  .pp-gf-content {
    padding: 0 !important;
  }
}

/** campaign page donate page **/
.campaign-donate-form {
  .gform_page_fields {
    ul li {
      font-size: 15px;
      font-family: $open-sans !important;
      color: #2B0913 !important;
    }
  }

  .fl-module-content {
    max-width: 650px;
    margin: 0px auto;
    
    .pp-gf-content {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 45px;
      padding-right: 45px;

      .gfield_label {
        margin-bottom: 12px;
      }

      .select-amount .gfield_label {
        margin-bottom: 0px !important;
      }

      .gform_heading, .gf_page_steps, .amount_total {
        display: none;
      }

      .ginput_container {
        label {
          color: #BFB5B8;
        }
      }

      .ginput_container_radio {
        margin-top: 0px;
        ul {
          display: flex;
          align-items: center;
          margin: 0px;

          li label {
            padding-left: 28px;
            color: #2B0913;
          }

          li {
            line-height: 0px;
            margin: 0px;
          }

          label {
            line-height: 28px;
          }
        }

        @media (max-width: 634px) {
          ul {
            margin-top: 8px;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }

      .payment-method {
        margin-bottom: 30px !important; 
      }

      #field_16_4 ul.gfield_radio li input[type=radio]:checked+label {
        background-color: #FFFFFF;
        border: 1px solid #BFB5B8;
        border-radius: 23px;
        box-shadow: none;
      }

      input, select {
        background-color: #FFFFFF !important;
        border: 1px solid #B3ABAE !important;
        padding: 8px !important;
        color: #2B0913 !important;
        font-family: $body-font-family !important;
      }

      input::placeholder {
        color: #BFB5B8;
      }

      .gform_page_footer {
        text-align: center;
        .button {
          background-color: #232323 !important;
          padding: 10px 25px !important;
          color: #fff !important;
          font-size: 20px;
          font-weight: 300;
          letter-spacing: 0.8px;
          line-height: 24px;
        }
      }

      .gfield_html {
        display: none;
      }
    }
  }

}

.gf_page_steps {
  text-align: right;
}

.gf_step {
  width: 180px !important;
  text-align: left !important;
  margin: 0 !important;
  span.gf_step_number {
    display: inline-block;
    width: 26px;
    height: 26px;
    text-align: center;
    vertical-align: middle;
    border-radius: 100%;
    font-family: $open-sans !important;
    font-size: 14px;
    font-weight: bold;
    color: #D8D9DB;
    border: 1px solid #D8D9DB;
    background-color: #FFFFFF;
    line-height: 23px;
    margin-right: 0 !important;
  }
}

.gf_step_first {
  width: 180px !important;
  text-align: left !important;
  position: relative;
}

.gf_step_active, .gf_step_completed {
  position: relative;
}

.gf_step_active::after {
  content: "";
  width: 154px;
  right: 0px;
  height: 1px;
  top: 13px;
  background: #D8D9DB;
  z-index: 9;
  position: absolute;
}

#gf_step_2_2,
#gf_step_14_2 {
  position: relative;
  &::after {
    content: "";
    width: 154px;
    right: 0px;
    height: 1px;
    top: 13px;
    background: #D8D9DB;
    z-index: 9;
    position: absolute;
  }
}

.gf_step_completed::after {
  content: "";
  width: 154px;
  right: 0px;
  height: 1px;
  top: 13px;
  background: #98012E !important;
  z-index: 9;
  position: absolute;
}

.gf_step_last::after {
  content: "";
  display: none !important;
}

.gf_step_first span.gf_step_number, .gf_step_active span.gf_step_number, .gf_step_completed span.gf_step_number {
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  vertical-align: middle;
  border-radius: 100%;
  font-family: $open-sans;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  border: 1px solid #98012E;
  background-color: #98012E;
  line-height: 23px;
}

.gf_step_last {
  width: auto !important;
}

.gform_wrapper .gf_step {
  opacity: 1 !important;
}

#field_2_3 {
  label.gfield_label {
    width: auto;
    margin-right: 10px;
    display: inline-block !important;
  }
  .ginput_container_radio {
    display: inline-block !important;
    vertical-align: sub;
  }
}

#field_2_4 .gfield_radio {
  .gchoice_2_4_0 input, .gchoice_2_4_1 input, .gchoice_2_4_2 input {
    display: none;
  }
  .gchoice_2_4_0 label, .gchoice_2_4_1 label, .gchoice_2_4_2 label {
    border: 1px solid #BFB5B8;
    border-radius: 23px;
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-family: $open-sans;
    color: #000000;
    width: 100%;
    font-weight: 400 !important;
    opacity: .5;
    max-width: 95%;
  }
}

.gform_wrapper ul {
  &.gfield_checkbox li input[type=checkbox]:checked + label, &.gfield_radio li input[type=radio]:checked + label {
    opacity: 1 !important;
  }
}

#field_2_4 .gfield_radio {
  .gchoice_2_4_0 {
    width: 204px !important;
    margin-right: 20px;
  }
  .gchoice_2_4_1 {
    width: 105px !important;
    margin-right: 20px;
    label {
      text-indent: -999px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 80%;
        height: 80%;
        background: url("/wp-content/uploads/2018/03/paypal.png") no-repeat scroll center (center / contain);
        z-index: 99;
        top: 10%;
        left: 10%;
      }
    }
  }
  .gchoice_2_4_2 {
    width: 180px !important;
    margin-right: 20px;
  }
}

#field_2_5 label {
  color: #000000;
  font-family: $open-sans;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.gform_wrapper .gform_page_footer {
  border: none !important;
}

#gform_page_2_1 .gform_page_footer {
  text-align: center;
  .button {
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    background: url("/wp-content/uploads/2018/02/Path-2.png") no-repeat scroll right (top / 11px) 15px content-box;
    width: 240px;
    text-align: left;
    font-family: $main-font;
    line-height: 20px;
    font-size: 16px;
    padding-top: 14px;
    color: #98012E;
  }
}

#field_2_7 .ginput_container_text input {
  width: 100% !important;
}

#field_2_9, #field_2_10, #field_2_11, #field_2_12 {
  width: 50%;
  float: left;
  clear: none;
}

#field_2_9 input, #field_2_10 input, #field_2_11 input, #field_2_12 input, #field_2_13 input {
  width: 100% !important;
}

.donate-form .fl-module-content {
  .ginput_container_select select.gfield_select {
    width: 100% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    font-size: 16px;
    line-height: 21px;
    font-family: $open-sans;
    color: #000000;
    background-color: transparent;
    background-image: url("/wp-content/uploads/2018/01/down-icon.png");
    background-repeat: no-repeat;
    background-position: right 13px top 18px;
    background-size: 13px 8px;
    border: 1px solid #BFB5B8 !important;
    border-radius: 0;
    padding-right: 48px;
  }
  #field_2_1, #field_2_2, #field_2_3, #field_2_4 {
    margin-bottom: 35px;
  }
  #field_2_17 {
    display: inline-block !important;
    width: 60%;
    vertical-align: top;
    .ginput_container {
      margin-top: 0 !important;
      ul li.gchoice_2_17_1 label {
        color: #000000;
        font-family: $open-sans;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
      }
    }
  }
  #field_2_3 {
    vertical-align: middle;
    width: 60%;
  }
}

#field_2_3.recurring-months > label.gfield_label {
  display: none !important;
}

.donate-form .fl-module-content {
  #field_2_3 .ginput_container {
    width: 100% !important;
    margin-top: 0 !important;
    ul {
      width: 100% !important;
      li {
        width: 50% !important;
        input[type=radio] {
          display: none;
        }
        label {
          padding-left: 35px;
        }
        input[type=radio] {
          label {
            position: relative;
            cursor: pointer;
          }
          + label:before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #888;
            position: absolute;
            left: 0;
            top: 6px;
          }
          &:checked + label {
            position: relative;
            font-weight: 400;
            &:after {
              content: "";
              position: absolute;
              top: auto;
              top: 3px;
              left: -4px;
              width: 20px;
              height: 20px;
              border: 1px solid #000;
              border-radius: 50%;
              display: inline-block;
              cursor: pointer;
            }
            &:before {
              background-color: #000;
              width: 10px;
              height: 10px;
              left: 1px;
              top: 8px;
            }
          }
        }
      }
    }
  }
  .gform_validation_error #field_2_3 .ginput_container ul li input[type=radio] + label {
    border: 1px solid #ff0f0f;
  }
}

#field_2_3.gfield_visibility_visible.hidden, #field_2_17.gfield_visibility_visible.hidden {
  display: none !important;
}

.ginput_container_radio ul li {
  vertical-align: middle;
  &.gchoice_2_2_0, &.gchoice_2_2_1, &.gchoice_2_2_2 {
    width: 18%;
  }
  &.gchoice_2_2_3 {
    width: 46%;
    input {
      &[type="radio"] {
        display: none;
      }
      &[type="text"] {
        font-family: $open-sans !important;
        font-size: 16px !important;
      }
    }
  }
}

.gfield_creditcard_warning_message {
  display: none !important;
  ~ label {
    display: none !important;
  }
}

.gfield_creditcard_warning {
  border: none !important;
  background: transparent !important;
}

.ginput_container_creditcard {
  .security_field {
    margin-left: 2%;
    width: 48%;
  }
  .card_field {
    margin-right: 2%;
    width: 48%;
    margin-top: 12px;
  }
  .card_name_field label, .card_field label, .security_field label, .ginput_card_expiration_container label {
    color: #000000;
    font-family: $open-sans;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 12px !important;
  }
  .card_name_field {
    margin-bottom: 20px !important;
  }
  .card_field, .security_field {
    margin-bottom: 40px !important;
  }
}

.gform_page .ginput_container_creditcard {
  .card_name_field input, .card_field input, .security_field input, .ginput_card_expiration_container input {
    font-family: $open-sans !important;
    font-size: 16px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.donate-form .gform_wrapper .ginput_complex .ginput_cardinfo_right input.ginput_card_security_code {
  width: 100% !important;
  max-width: 100% !important;
}

.gform_page .ginput_container_creditcard {
  .ginput_card_security_code_icon {
    display: none !important;
  }
  .card_field label {
    margin-bottom: 6px !important;
  }
  .ginput_cardinfo_right label {
    margin-top: 17px !important;
  }
}

.ginput_container_creditcard {
  .card_field label {
    .card_icons {
      display: inline-block !important;
      margin: 0 !important;
      vertical-align: middle !important;
    }
    .gform_card_icon_container {
      display: inline-block;
      height: 25px !important;
      margin: 0 0 0 2px !important;
      background: url("/wp-content/uploads/2018/03/creditcards.png") no-repeat;
      .gform_card_icon {
        background: transparent !important;
      }
    }
  }
  .col-sm-12, .col-sm-6 {
    padding: 0 !important;
  }
}

.ginput_cardinfo_left {
  width: 100% !important;
}

.donation-form .ginput_container_creditcard .ginput_cardinfo_left .ginput_card_expiration_container select {
  width: 48% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  font-size: 16px;
  line-height: 21px;
  font-family: $open-sans;
  color: #000000;
  background-color: transparent;
  background-image: url("/wp-content/uploads/2018/01/down-icon.png");
  background-repeat: no-repeat;
  background-position: right 13px top 18px;
  background-size: 13px 8px;
  border: 1px solid #BFB5B8 !important;
  border-radius: 0;
  margin: 0;
  &.ginput_card_expiration_month {
    width: 48% !important;
    margin-right: 1.6% !important;
    margin-left: 0 !important;
  }
  &.ginput_card_expiration_year {
    width: 48% !important;
    margin-left: 1.6% !important;
    margin-right: 0 !important;
  }
}

#gform_page_2_3 .gform_page_footer {
  #gform_previous_button_2_23 {
    border: none !important;
    color: #232323;
    font-size: 16px;
    line-height: 26px;
    font-family: $main-font;
    padding-left: 20px;
    background: url("/wp-content/uploads/2018/02/Left-Arrow.png") no-repeat scroll left (top / 12px) 20px content-box;
    width: 130px;
    margin-right: 50px;
  }
  .button#gform_next_button_2_23 {
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #98012E !important;
    width: 300px;
    font-family: $main-font;
    line-height: 22px;
    font-size: 18px;
    padding-top: 14px;
    color: #fff !important;
    letter-spacing: 1px;
  }
}
#gform_page_2_4 .gform_page_footer {
  #gform_previous_button_2 {
    border: none !important;
    color: #232323;
    font-size: 16px;
    line-height: 26px;
    font-family: $main-font;
    padding-left: 20px;
    background: url("/wp-content/uploads/2018/02/Left-Arrow.png") no-repeat scroll left (top / 12px) 20px content-box;
    width: 130px;
    margin-right: 50px;
  }
  .button#gform_submit_button_2 {
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #98012E !important;
    width: 300px;
    font-family: $main-font;
    line-height: 22px;
    font-size: 18px;
    padding-top: 14px;
    color: #fff !important;
    letter-spacing: 1px;
  }
}

.gchoice_2_2_3 input#input_2_2_other {
  width: 93% !important;
}


.recurringThanksMsg, .thanksForFullDonation, .thanksForSignMeUp {
  color: #000000;
  font-family: $open-sans;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  margin: 40px 0;
  padding-left: 35px;
  position: relative;
}

.recurringThanksMsg::before, .thanksForFullDonation::before, .thanksForSignMeUp::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 19px;
  background: url("/wp-content/uploads/2018/02/Checkmarl.png") no-repeat scroll center (center / contain);
}

.monthly-donation-label label.gfield_label, .processing-fees label.gfield_label {
  display: none !important;
}

/* step 2 */

#gform_page_2_2 {
  .gform_page_footer {
    #gform_previous_button_2_15 {
      border: none !important;
      color: #232323;
      font-size: 16px;
      line-height: 26px;
      font-family: $main-font;
      padding-left: 20px;
      background: url("/wp-content/uploads/2018/02/Left-Arrow.png") no-repeat scroll left (top / 12px) 20px content-box;
      width: 120px;
    }
    .button#gform_next_button_2_15 {
      position: relative;
      padding-right: 20px;
      padding-left: 5px;
      background: url(/wp-content/uploads/2018/02/Path-2.png) no-repeat scroll right (top / 11px) 15px content-box;
      width: 280px;
      font-family: $main-font;
      line-height: 20px;
      font-size: 16px;
      padding-top: 14px;
      color: #98012E;
    }
  }
  #field_2_18 {
    margin-bottom: 20px !important;
  }
}

#gform_fields_2_2 {
  li {
    .ginput_container span label {
      display: none !important;
    }
    &#field_2_19 .ginput_container span {
      margin-bottom: 30px;
      &.address_country, &.address_zip {
        margin-bottom: 10px;
      }
    }
    .ginput_container {
      input {
        font-family: $open-sans;
        color: #000000;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .ginput_container span {
      select {
        width: 100% !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        font-size: 16px;
        line-height: 21px;
        font-family: $open-sans;
        color: #000000;
        background-color: transparent;
        background-image: url("/wp-content/uploads/2018/01/down-icon.png");
        background-repeat: no-repeat;
        background-position: right 13px top 18px;
        background-size: 13px 8px;
        border: 1px solid #BFB5B8 !important;
        border-radius: 0;
      }
      &.address_line_2 {
        display: none !important;
      }
      input {
        font-family: $open-sans;
        color: #000000;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .ginput_container_email input {
    font-family: $open-sans;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
  }
  #field_2_14 label.gfield_label {
    display: none !important;
  }
  #field_2_13 {
    margin-bottom: 20px;
  }
  #field_2_14 .gfield_checkbox .gchoice_2_14_1 label {
    color: #000000;
    font-family: $open-sans;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }
}

.donate-locate h4 {
  font-family: $open-sans;
  color: #2D171E;
}

.donate-form .gform_wrapper .gfield_required {
  color: #000000 !important;
}
/* custom radio button */

#field_2_2 ul.gfield_radio li {
  input[type=radio] {
    display: none;
  }
  label {
    padding-left: 35px;
  }
  input[type=radio] {
    label {
      position: relative;
      cursor: pointer;
    }
    + label:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #888;
      position: absolute;
      left: 0;
      top: 6px;
    }
    &:checked + label {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: auto;
        top: 3px;
        left: -4px;
        width: 20px;
        height: 20px;
        border: 1px solid #000;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
      }
      &:before {
        background-color: #000;
        width: 10px;
        height: 10px;
        left: 1px;
        top: 8px;
      }
    }
  }
}

/* custom checkbox */

#field_2_17 ul.gfield_checkbox li input[type=checkbox], #field_2_5 ul.gfield_checkbox li input[type=checkbox], #field_2_14 ul.gfield_checkbox li input[type=checkbox] {
  display: none;
}

#field_2_17 ul.gfield_checkbox li label, #field_2_5 ul.gfield_checkbox li label, #field_2_14 ul.gfield_checkbox li label {
  padding-left: 35px;
}

#field_2_17 ul.gfield_checkbox li input[type=checkbox] label, #field_2_5 ul.gfield_checkbox li input[type=checkbox] label, #field_2_14 ul.gfield_checkbox li input[type=checkbox] label {
  position: relative;
  cursor: pointer;
}

#field_2_17 ul.gfield_checkbox li input[type=checkbox] + label:before, #field_2_5 ul.gfield_checkbox li input[type=checkbox] + label:before, #field_2_14 ul.gfield_checkbox li input[type=checkbox] + label:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #888;
  position: absolute;
  left: 0;
  top: 6px;
}

#field_2_17 ul.gfield_checkbox li input[type=checkbox]:checked + label, #field_2_5 ul.gfield_checkbox li input[type=checkbox]:checked + label, #field_2_14 ul.gfield_checkbox li input[type=checkbox]:checked + label {
  position: relative;
}

#field_2_17 ul.gfield_checkbox li input[type=checkbox]:checked + label:after, #field_2_5 ul.gfield_checkbox li input[type=checkbox]:checked + label:after, #field_2_14 ul.gfield_checkbox li input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  top: auto;
  top: 3px;
  left: 0px;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

#field_2_17 ul.gfield_checkbox li input[type=checkbox]:checked + label:before, #field_2_5 ul.gfield_checkbox li input[type=checkbox]:checked + label:before, #field_2_14 ul.gfield_checkbox li input[type=checkbox]:checked + label:before {
  background-color: #000;
  width: 10px;
  height: 10px;
  left: 5px;
  top: 8px;
}

.donate-form .validation_error {
  display: none !important;
}
/***** single page gravity form css *****/

#gform_wrapper_5.gform_wrapper .gform_body ul li span {
  input ~ label, select ~ label {
    display: none !important;
  }
}

#gform_wrapper_4.gform_wrapper .gform_body ul li span {
  select ~ label, input ~ label {
    display: none !important;
  }
}

#gform_wrapper_3.gform_wrapper .gform_body ul li span {
  select ~ label, input ~ label {
    display: none !important;
  }
}

#gform_wrapper_6.gform_wrapper .gform_body ul li span {
  select ~ label, input ~ label {
    display: none !important;
  }
}

#gform_wrapper_5.gform_wrapper .gform_body ul li span input {
  margin-bottom: 20px;
}

#gform_wrapper_4.gform_wrapper .gform_body ul li span {
  input, textarea {
    margin-bottom: 20px;
  }
}

#gform_wrapper_6.gform_wrapper .gform_body ul li span {
  input, textarea {
    margin-bottom: 20px;
  }
}

#gform_wrapper_5.gform_wrapper .gform_body ul li span, #gform_wrapper_4.gform_wrapper .gform_body ul li span, #gform_wrapper_3.gform_wrapper .gform_body ul li span, #gform_wrapper_6.gform_wrapper .gform_body ul li span {
  margin-bottom: 20px;
}

#gform_wrapper_5.gform_wrapper .gform_body ul li > label, #gform_wrapper_4.gform_wrapper .gform_body ul li > label, #gform_wrapper_3.gform_wrapper .gform_body ul li > label, #gform_wrapper_6.gform_wrapper .gform_body ul li > label {
  color: #000000;
  font-family: $open-sans;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

#gform_wrapper_5.gform_wrapper .gform_body input, #gform_wrapper_4.gform_wrapper .gform_body input, #gform_wrapper_3.gform_wrapper .gform_body input {
  font-family: $open-sans !important;
  font-size: 16px !important;
  color: #000000;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #BFB5B8;
  padding: 10px;
  border-radius: 2px;
  width: 100% !important;
}

#gform_wrapper_6.gform_wrapper {
  .gform_body input, .ginput_container textarea {
    font-family: $open-sans !important;
    font-size: 16px !important;
    color: #000000;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid #BFB5B8;
    padding: 10px;
    border-radius: 2px;
    width: 100% !important;
  }
}

#gform_wrapper_5.gform_wrapper .gfield_checkbox input, #gform_wrapper_4.gform_wrapper .gfield_checkbox input, #gform_wrapper_3.gform_wrapper .gfield_checkbox input, #gform_wrapper_6.gform_wrapper .gfield_checkbox input ,#gform_wrapper_5.gform_wrapper .gfield_radio input, #gform_wrapper_4.gform_wrapper .gfield_radio input, #gform_wrapper_3.gform_wrapper .gfield_radio input, #gform_wrapper_6.gform_wrapper .gfield_radio input {
  width: auto !important;
}

#gform_wrapper_5.gform_wrapper .gform_body input::placeholder, #gform_wrapper_4.gform_wrapper .gform_body input::placeholder, #gform_wrapper_3.gform_wrapper .gform_body input::placeholder, #gform_wrapper_6.gform_wrapper .gform_body input::placeholder {
  color: #000000;
}

#gform_wrapper_5.gform_wrapper .gform_body select, #gform_wrapper_4.gform_wrapper .gform_body select, #gform_wrapper_3.gform_wrapper .gform_body select, #gform_wrapper_6.gform_wrapper .gform_body select {
  width: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  font-size: 16px;
  line-height: 21px;
  font-family: $open-sans;
  color: #000000;
  background-color: transparent;
  background-image: url(/wp-content/uploads/2018/01/down-icon.png);
  background-repeat: no-repeat;
  background-position: right 13px top 18px;
  background-size: 13px 8px;
  border: 1px solid #BFB5B8 !important;
  border-radius: 0;
  padding: 10px 10px;
}

#gform_wrapper_5.gform_wrapper .gform_footer input.button, #gform_wrapper_4.gform_wrapper .gform_footer input.button, #gform_wrapper_3.gform_wrapper .gform_footer input.button, #gform_wrapper_6.gform_wrapper .gform_footer input.button {
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #98012E !important;
  width: 300px;
  font-family: $main-font;
  line-height: 20px;
  font-size: 16px;
  padding: 10px;
  color: #fff !important;
  border: 1px solid #98012E !important;
  &:hover {
    color: #98012E !important;
    background: transparent !important;
    border: 1px solid #98012E !important;
  }
}
/* deyneka award*/
#gform_wrapper_3 .gfield_description ,
#gform_wrapper_3 .ginput_counter{
  font-family: $open-sans !important;
  font-size: 16px !important;
}
#gform_fields_2_2 li {
  margin-bottom: 20px !important;
}

.gf_hide_labels label{
  display:none;
}

.gfield_html {
  font-size: 12px;
}
