@mixin box-shadow($shadow-color){
  -webkit-box-shadow: $shadow-color;
  -moz-box-shadow: $shadow-color;
  -ms-box-shadow: $shadow-color;
  -o-box-shadow: $shadow-color;
  box-shadow: $shadow-color;
}
@mixin flex-container($flex-value){
  -webkit-display: $flex-value;
  -moz-display: $flex-value;
  -ms-display: $flex-value;
  -o-display: $flex-value;
  display: $flex-value;
}
@mixin flex-wrap($wrap-value){
  -webkit-flex-wrap:: $wrap-value;
  -moz-flex-wrap:: $wrap-value;
  -ms-flex-wrap:: $wrap-value;
  -o-flex-wrap:: $wrap-value;
  flex-wrap: $wrap-value;
}
@mixin align-self($align-value){
  -webkit-align-self: $align-value;
  -moz-align-self: $align-value;
  -ms-align-self: $align-value;
  -o-align-self: $align-value;
  align-self: $align-value;
}
@mixin transition($transition-time) {
  -webkit-transition: $transition-time ease-in-out;
  -moz-transition: $transition-time ease-in-out;
  -ms-transition: $transition-time ease-in-out;
  -o-transition: $transition-time ease-in-out;
  transition: $transition-time ease-in-out;
}
@mixin column-count($count-value){
  -webkit-column-count: $count-value;
  -moz-column-count: $count-value;
  -ms-column-count: $count-value;
  -o-column-count: $count-value;
  column-count: $count-value;
}
@mixin column-fill($fill-value){
  -webkit-column-fill: $fill-value;
  -moz-column-fill: $fill-value;
  -ms-column-fill: $fill-value;
  -o-column-fill: $fill-value;
  column-fill: $fill-value;
}
@mixin column-gap($gap-value){
  -webkit-column-gap: $gap-value;
  -moz-column-gap: $gap-value;
  -ms-column-gap: $gap-value;
  -o-column-gap: $gap-value;
  column-gap: $gap-value;
}
@mixin image-fit($fit-value) {
  -webkit-object-fit: $fit-value;
  -moz-object-fit: $fit-value;
  ms-object-fit: $fit-value;
  -o-object-fit: $fit-value;
  object-fit: $fit-value;
}
