.donate-form {

  .gform_body #field_2_40 {
    margin-top: 0 !important;
    margin-left: 20px !important;
  }

  /*Donation total section*/
  .donation-form_wrapper {
    
    .gfield {
      &.amount_total {
        margin-top: 15px !important;
        
        label {
          display: inline-block !important;
          width: 50%;
        }

        .ginput_container_total {
          padding: 5px 10px !important;
          display: inline-block;
          width: 50%;
          
          span {
            display: inline-block;
            font-size: 16px;
            line-height: 21px;
            font-family: $open-sans;
            color: #000000;
            
            &:after {
              content: 'USD';
              display: inline-block;
              margin-left: 6px;
            }
          }
        }

        @media screen and (min-width: 769px) {
          label {
            width: 20%;
          }

          .ginput_container_total {
            width: 80%;
          }
        }
      }
    }
  }
}